body {
  background-color: #f0f4f8;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.App {
  text-align: center;
}

.App-nav {
  background-color: #007bff; /* Bright blue */
  padding: 10px;
}

.App-nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

.App-nav ul li {
  margin: 0 15px;
}

.App-nav ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.App-nav ul li a:hover {
  text-decoration: underline;
}

.Home-header, .About-header {
  background-color: #007bff;
  color: #fff;
  padding: 20px;
}

.Home-main, .About-main {
  padding: 20px;
  background-color: #fff; /* White background for main content */
}

label {
  display: block;
  margin-bottom: 10px;
  color: #333;
}

input, textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
