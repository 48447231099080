/* Add a background image to the Home component */
.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 0 20px;
  box-sizing: border-box;
  background-image: url('/images/woo-background.webp'); /* Path from the public folder */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.simplelink {
    text-decoration:none;
}
.Home-header {
  text-align: center;
  margin-bottom: 20px;
  color: #fff; /* Make text white to contrast with the background */
  padding: 0 10%; /* Add padding to left and right */
  margin-top: 20px; /* Add space between top menu and title */
}

.Home-header h1 {
  font-size: 1.5rem; /* Default size for small screens, reduced further */
  margin: 0;
}

.Home-header p {
  font-size: 0.9rem; /* Default size for small screens, reduced further */
}

.bigphone {
  font-size: 1em;
  margin-top: 0;
}

.Home-main {
  border-radius: 10px;
  opacity: .85;
  text-align: center;
  margin-top: 20px; /* Default margin-top for small screens */
}

.Home-main p {
  font-size: 1rem; /* Default size for small screens */
  margin-bottom: 20px;
}

/* Adjust styles for larger screens */
@media (min-width: 768px) {
  .Home-header h1 {
    font-size: 2rem;
  }

  .Home-header p {
    font-size: 1rem;
  }

  .Home-main p {
    font-size: 1.2rem;
  }

  .bigphone {
    font-size: 1.1em;
  }

  .Home-main {
    margin-top: 10px; /* Reduce margin-top for tablet view */
  }
}

@media (min-width: 1200px) {
  .Home-header h1 {
    font-size: 3rem;
  }

  .Home-header p {
    font-size: 1.5rem;
  }
 
  .Home-main p {
    font-size: 1.5rem;
  }

  .bigphone {
    font-size: 1.2em;
  }
}

/* Adjust image size on mobile */
@media (max-width: 767px) {
  .Home img {
    width: 75%; /* Reduce the image size by 25% */
    height: auto; /* Maintain aspect ratio */
  }
}

